import React from 'react';
import {
  Linking,
  Text,
  View,
  Platform,
  useWindowDimensions,
  StyleSheet,
  FlatList,
  TouchableOpacity,
} from 'react-native';
import { Ionicons } from '@expo/vector-icons';

function Details({ loadDetails }) {
  const { height, width } = useWindowDimensions();
  const styles = GetStyles(height, width);

  //Rep Phone
  const ext = loadDetails?.ext;
  function DialUrl() {
    const phone = '(614) 927-1200';
    const dialUrl = ext
      ? `tel:${phone.replace(/\D/g, '')};` + `ext=${ext}`
      : `tel:${phone.replace(/\D/g, '')}`;
    Linking.openURL(dialUrl);
  }

  //Next Stop Address
  const address = loadDetails?.nextStop?.address;
  function OpenAddress() {
    const formattedAddress = encodeURIComponent(address);
    const addressUrl = Platform.select({
      ios: `maps:0,0?q=${formattedAddress}`,
      android: `geo:0,0?q=${formattedAddress}`,
      web: navigator?.userAgent?.includes('iPhone')
        ? `maps:0,0?q=${formattedAddress}`
        : `https://www.google.com/maps?q=${formattedAddress}`,
    });
    Linking.openURL(addressUrl);
  }

  const DisplayLoadDetails = () => {
    // const rep = loadDetails?.rep;
    // const ext = loadDetails?.ext;
    // const temp = loadDetails?.temp;
    // const ref = loadDetails?.nextStop?.reference;
    const rep = 'Trevor Westin';
    const ext = '000';
    const temp = 'supa hot';
    const ref = '123456789';

    const details = [
      { label: 'Contact', value: rep, icon: 'person' },
      {
        label: 'Dial Ext',
        value: ext && `(614) 927-1200, Ext. ${ext}`,
        HandlePress: DialUrl,
        icon: 'call',
      },
      { label: 'Temp', value: temp && temp + '°', icon: 'snow' },
      { label: 'Reference', value: ref, icon: 'barcode' },
    ];

    return (
      <>
        <Text style={styles.title}>Load #{loadDetails?.loadId}</Text>
        {/* <View style={styles.divider} /> */}
        <FlatList
          scrollEnabled={false}
          data={details}
          renderItem={({ item }) =>
            item.value && (
              <TouchableOpacity
                onPress={item.HandlePress}
                disabled={!item.HandlePress}
                style={{ flex: 1 }}
              >
                <View style={styles.item}>
                  <View style={styles.icon}>
                    {item.icon && (
                      <Ionicons name={item.icon} size={30} color="black" />
                    )}
                  </View>
                  <View style={styles.content}>
                    <Text style={styles.label}>{item.label}</Text>
                    <Text
                      style={[
                        styles.value,
                        styles.loadMaxWidth,
                        item.HandlePress && styles.link,
                      ]}
                    >
                      {item.value}
                    </Text>
                  </View>
                </View>
              </TouchableOpacity>
            )
          }
          keyExtractor={item => item.label}
          numColumns={1}
        />
      </>
    );
  };

  const DisplayStopDetails = () => {
    // const stop = loadDetails?.nextStop;
    // const type = stop?.stopType;
    // const name = stop?.locationName;
    // const adres = stop?.address;
    // const date = stop?.aptDate;
    // const time = stop?.apTime;
    const stop = true;
    const type = 'Drop';
    const name = 'Chipotle';
    const adres = '2048 E Pro Gamer St, Columbus, OH 4321500';
    const date = '20/20/20';
    const time = '25:61 AM';

    const details = [
      { label: 'Location', value: name, icon: 'location' },
      {
        label: 'Address',
        value: adres,
        icon: 'map',
        HandlePress: OpenAddress,
      },
      {
        label: 'Date/Time',
        value: `${date != null ? date : 'Date TBD'} ${
          time != null ? time : 'Time TBD'
        }`,
        icon: 'calendar',
      },
    ];

    return (
      type && (
        <>
          {/* <Text style={styles.title}>{type} Details</Text> */}
          <Text style={styles.title}>Stop Details</Text>
          {/* <View style={styles.divider} /> */}
          <FlatList
            scrollEnabled={false}
            data={details}
            renderItem={({ item }) =>
              item.value && (
                <TouchableOpacity
                  onPress={item.HandlePress}
                  disabled={!item.HandlePress}
                  style={{ flex: 1 }}
                >
                  <View style={styles.item}>
                    <View style={styles.icon}>
                      {item.icon && (
                        <Ionicons name={item.icon} size={30} color="black" />
                      )}
                    </View>
                    <View style={styles.content}>
                      <Text style={styles.label}>{item.label}</Text>
                      <Text
                        style={[
                          styles.value,
                          styles.stopMaxWidth,
                          item.HandlePress && styles.link,
                        ]}
                      >
                        {item.value}
                      </Text>
                    </View>
                  </View>
                </TouchableOpacity>
              )
            }
            keyExtractor={item => item.label}
            numColumns={1}
          />
        </>
      )
    );
  };

  return (
    <View style={styles.detailsContainer}>
      <DisplayLoadDetails />
      <DisplayStopDetails />
    </View>
  );
}
export default Details;

function GetStyles(height, width) {
  return StyleSheet.create({
    detailsContainer: {
      width: '100%',
      paddingHorizontal: 20,
      alignSelf: 'center',
      color: '#353536',
    },
    title: {
      width: '100%',
      backgroundColor: '#353536',
      fontSize: 20,
      fontWeight: 'bold',
      textTransform: 'uppercase',
      textAlign: 'center',
      marginTop: 40,
      color: 'white',
      paddingVertical: 8,
      borderRadius: 8,
      overflow: 'hidden',
    },
    divider: {
      height: 3,
      width: '100%',
      backgroundColor: '#353536',
      marginVertical: 8,
    },

    item: {
      flexDirection: 'row',
      alignItems: 'start',
      marginVertical: 8,
      // backgroundColor: 'red',
    },
    icon: {
      width: 30,
      height: 40,
      marginRight: 10,
      alignItems: 'center',
      justifyContent: 'center',
      // backgroundColor: 'orange',
    },
    content: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      minheight: 30,
    },
    label: {
      fontSize: 12,
    },
    value: { fontSize: 16, fontWeight: 'bold', flexWrap: 'wrap' },
    loadMaxWidth: {
      maxWidth: (width - 120) / 2,
    },
    stopMaxWidth: {
      maxWidth: width - 80,
    },
    link: {
      color: '#0000a0',
      textDecorationLine: 'underline',
    },
  });
}
