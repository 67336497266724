import {
  View,
  Text,
  Image,
  useWindowDimensions,
  StyleSheet,
  Animated,
  Easing,
} from 'react-native';
import GetGlobalStyles from '../assets/GlobalStyles';
import ExitSign from '../assets/ExitSign.png';
import Header from '../Components/Header';
import LoadRCTemplate from '../assets/LoadRCTemplate.png';
import { useEffect, useState, useRef } from 'react';
import Four0FourSign from '../Components/Helper/Four0FourSign';

export default function FourOFourPage() {
  const { height, width } = useWindowDimensions();

  const globalStyles = GetGlobalStyles(height);
  const [total, setTotal] = useState(0);
  const [isOnLeft, setIsOnLeft] = useState(true);
  const positionLeft = useRef(new Animated.Value(-400)).current;
  const textOpacity = useRef(new Animated.Value(0)).current;

  const styles = StyleSheet.create({
    container: {
      width: '100%',
      height: '100%',
      justifyContent: 'center',
      alignItems: 'center',
    },
    FourOFourContainer: {
      justifyContent: 'center',
      flexDirection: 'row',
    },
    PageNotFoundText: {
      //Font styling
      marginVertical: 50,
      fontSize: 25,
      textAlign: 'center',
      color: 'white',
    },
    // ExitSign: {
    //   //Styling the Sign
    //   height: 0.4 * height,
    //   width: 0.45 * height,
    // },
  });

  function HandlePress(letter) {
    switch (letter) {
      case 'C':
        setTotal(1);
        break;

      case 'A':
        if (total === 1) {
          setTotal(2);
        } else {
          setTotal(0);
        }
        break;

      case 'P':
        if (total === 2) {
          setTotal(3);
        } else {
          setTotal(0);
        }
        break;

      case 'Y':
        if (total === 3) {
          isOnLeft ? StartAnimation() : StartBackwardAnimation();
          setIsOnLeft(!isOnLeft);
        }
        setTotal(0);
    }
  }

  async function StartAnimation() {
    await Animated.sequence([
      Animated.timing(positionLeft, {
        toValue: 0.5 * width - 200,
        duration: 1000,
        easing: Easing.out(Easing.exp),
        useNativeDriver: false,
      }),
      Animated.timing(positionLeft, {
        toValue: width,
        duration: 1000,
        easing: Easing.exp,
        useNativeDriver: false,
      }),
    ]).start();
  }
  async function StartBackwardAnimation() {
    await Animated.sequence([
      Animated.timing(positionLeft, {
        toValue: 0.5 * width - 200,
        duration: 1000,
        easing: Easing.out(Easing.exp),
        useNativeDriver: false,
      }),
      Animated.timing(positionLeft, {
        toValue: -400,
        duration: 1000,
        easing: Easing.exp,
        useNativeDriver: false,
      }),
    ]).start();
  }

  return (
    <View style={globalStyles.app}>
      <View style={styles.container}>
        {/* <Image source={ExitSign} style={styles.ExitSign} /> */}
        <Four0FourSign />
        <View style={styles.FourOFourContainer}>
          <Text style={styles.PageNotFoundText}>
            The{' '}
            <Text
              style={styles.PageNotFoundText}
              onPress={() => HandlePress('P')}
            >
              P
            </Text>
            age{' '}
            <Text
              style={styles.PageNotFoundText}
              onPress={() => HandlePress('Y')}
            >
              Y
            </Text>
            ou{' '}
            <Text
              style={styles.PageNotFoundText}
              onPress={() => HandlePress('A')}
            >
              A
            </Text>
            re Looking For{' '}
            <Text
              style={styles.PageNotFoundText}
              onPress={() => HandlePress('C')}
            >
              C
            </Text>
            ould Not Be Found
          </Text>
        </View>

        <Animated.Image
          source={LoadRCTemplate}
          style={{
            height: 400,
            width: 400,
            marginTop: 0.1 * height,
            position: 'absolute',
            left: positionLeft,
            transform: [{ scaleX: !isOnLeft ? 1 : -1 }],
          }}
        />
      </View>
    </View>
  );
}
