import BBIKnowCaller from '../api/BBIKnowCaller';
import { StoreData, GetData, ClearData } from './AsyncStorage';
import { Linking } from 'react-native';

// const loadDetails = {
//   loadId: 174458,
//   sessionId: 282,
//   rep: 'Ben Herzog',
//   office: 1,
//   ext: '634',
//   temp: '32',
//   nextStop: {
//     stopType: 'Drop',
//     locationName:
//       'UNFI, Greenwood Distribution Center 1234567890123456789012345678901234567890123456789012345678',
//     address: '71 Stow Dr, West Chesterfield, NH, 03466-3673, USA',
//     //address: 'NH, 03466-3673',
//     phone: '(603) 256-3000',
//     reference: 'Conf# 7527139',
//     aptDate: '6/23/2023',
//     apTime: '1:00 AM',
//   },
// };

export default async function GetLoad(guid) {
  try {
    if (guid) {
      let storeGuid; //boolean to determine whether to store sessionID and GUID or not based on response from v&c
      let loadDetails = null;
      await BBIKnowCaller.get(`Sessions/ValidateAndClaim/${guid}`)
        .then(response => {
          const load = response.data;
          if (load?.sessionId) {
            loadDetails = load;
          }
        })
        .catch(() => {
          storeGuid = false;
        })
        .finally(() => {
          if (storeGuid !== false) {
            ClearData();
            StoreData('bbidrive_sessionID', loadDetails.sessionId.toString());
            StoreData('bbidrive_sessionGUID', guid);
          }
        });
      return loadDetails;
    } else {
      return null;
    }
  } catch {
    return null;
  }
}
//presentPageUrl parameter is optional. This function is used to get the initial
//guid from url from when the page is opened, or to get the guid from the current window
export async function GetUrlGuid(presentPageUrl) {
  try {
    const initUrl = presentPageUrl
      ? window.location.href
      : await Linking.getInitialURL();
    var regex = /[?&]([^=#]+)=([^&#]*)/g,
      params = {},
      match;
    while ((match = regex.exec(initUrl))) {
      params[match[1]] = match[2];
    }
    const urlGuid = params?.id;
    return urlGuid;
  } catch {
    return null;
  }
}

export async function GetCookieGuid() {
  try {
    const clientGuid = await GetData('bbidrive_sessionGUID');
    return clientGuid?.value;
  } catch {
    return null;
  }
}

// async function ValidateClient() {
//   const [clientGuid, urlGuid] = await Promise.all([
//     GetCookieGuid(),
//     GetUrlGuid(),
//   ]);
//   const hasCookieId = clientGuid != null;
//   const hasUrlId = urlGuid != null;
//   let loadDetails = null;
//   if (hasUrlId && clientGuid != urlGuid)
//     loadDetails = await ValidateAndClaimSession(urlGuid);
//   if (hasCookieId && (!hasUrlId || clientGuid == urlGuid))
//     loadDetails = await ValidateClientsSession(clientGuid);
//   if (loadDetails == null || loadDetails == '') HandleDeny();
//   return loadDetails;
// }

// async function ValidateAndClaimSession(guid) {
//   let loadDetails = null;
//   await BBIKnowCaller.get(`Sessions/ValidateAndClaim/${guid}`)
//     .then(response => {
//       loadDetails = response.data;
//       if (loadDetails != null && loadDetails != '') {
//         StoreData('GUID', guid);
//       }
//     })
//     .catch(e => {
//       HandleDeny();
//     });
//   return loadDetails;
// }

// async function ValidateClientsSession(guid) {
//   let loadDetails = null;
//   await BBIKnowCaller.get(`Sessions/ValidateClient/${guid}`)
//     .then(response => {
//       loadDetails = response.data;
//       if (loadDetails != null && loadDetails != '') {
//         StoreData('GUID', guid);
//       }
//     })
//     .catch(e => {
//       HandleDeny();
//     });
//   return loadDetails;
// }

// async function HandleDeny() {
//   //RemoveData('GUID');
// }
