import {
  View,
  useWindowDimensions,
  Platform,
  TouchableOpacity,
  Text,
  Linking,
  ScrollView,
  RefreshControl,
  StyleSheet,
  Button,
} from 'react-native';
import GetGlobalStyles from '../assets/GlobalStyles';
import Header from '../Components/Header';
import AppSubscriptionSwitch from '../Components/AppSubscriptionSwitch';
import SubscriptionSwitchWeb from '../Components/SubscriptionSwitchWeb';
import BottomDrawerWeb from '../Components/BottomDrawerWeb';
import { GetUrlGuid } from '../auth/Load';
import BottomDrawerLab from '../Components/BottomDrawerLab';
import NewBottomTest from '../Components/NewBottomTest';

import React, { useState, useCallback, useRef, useMemo } from 'react';
import BottomSheet, {
  BottomSheetScrollView,
  BottomSheetView,
} from '@gorhom/bottom-sheet';
import Details from '../Components/Details';

//HomePage is the main page drivers will see. It includes load details and a switch to turn location on/off.
const HomePage = ({
  loadDetails,
  OpenModal,
  ResetGuid,
  isLoading,
  HandleRefreshSession,
}) => {
  // drawer hooks
  const sheetRef = useRef(null);

  // drawer variables
  const snapPoints = useMemo(() => ['25%', '50%', '100%'], []);

  // drawer callbacks
  const handleSheetChange = useCallback(index => {
    // console.log('handleSheetChange', index);
  }, []);
  const handleSnapPress = useCallback(index => {
    sheetRef.current?.snapToIndex(index);
  }, []);
  const handleClosePress = useCallback(() => {
    sheetRef.current?.close();
  }, []);

  // other
  const { height } = useWindowDimensions();
  const globalStyles = GetGlobalStyles(height);
  const [url, setUrl] = useState();

  const downloadedIsPossible =
    Platform.OS === 'web' &&
    (navigator.userAgent.includes('iPhone') ||
      navigator.userAgent.includes('iPad') ||
      navigator.userAgent.includes('Android'));

  async function RedirectToAppStore() {
    if (Platform.OS === 'web') {
      navigator.userAgent.includes('iPhone')
        ? Linking.openURL('https://apps.apple.com/us')
        : Linking.openURL('https://play.google.com/store/apps');
    }
  }

  async function OpenInApp() {
    const guid = await GetUrlGuid(window.location.href); //get urlguid from current window, not from the link when the page first opened
    setUrl(guid);
    await Linking.openURL(`bbidrive://?id=${guid}`);
  }

  return (
    <View style={styles.container}>
      {/* {Platform.OS === 'web' && downloadedIsPossible && (
        <TouchableOpacity
          onPress={OpenInApp}
          style={globalStyles.OpenInAppButton}
        >
          <Text style={globalStyles.OpenInAppText}>Open In the App</Text>
        </TouchableOpacity>
      )} */}

      {/* <Button title="Snap To 90%" onPress={() => handleSnapPress(2)} />
      <Button title="Snap To 50%" onPress={() => handleSnapPress(1)} />
      <Button title="Snap To 25%" onPress={() => handleSnapPress(0)} />
      <Button title="Close" onPress={() => handleClosePress()} /> */}

      {Platform.OS === 'web' ? (
        <SubscriptionSwitchWeb
          sessionId={loadDetails?.sessionId}
          hasNextStop={loadDetails?.nextStop}
        />
      ) : (
        <AppSubscriptionSwitch hasNextStop={loadDetails?.nextStop} />
      )}
    </View>
    // <ScrollView
    //   contentContainerStyle={{
    //     maxHeight: '100%',
    //     width: '100%',
    //     flexGrow: 1,
    //   }}
    //   refreshControl={
    //     <RefreshControl
    //       refreshing={isLoading}
    //       onRefresh={() => HandleRefreshSession()}
    //       // Android specific
    //       colors={['white']}
    //       progressBackgroundColor={'#353536'}
    //       // iOS specific
    //       tintColor={'#fff'}
    //       titleColor={'#fff'}
    //     />
    //   }
    // >
    //   <View style={globalStyles.app}>
    //     {/* {Platform.OS === 'web' && downloadedIsPossible && (
    //       <TouchableOpacity
    //         onPress={OpenInApp}
    //         style={globalStyles.OpenInAppButton}
    //       >
    //         <Text style={globalStyles.OpenInAppText}>Open In the App</Text>
    //       </TouchableOpacity>
    //     )} */}

    //     {Platform.OS === 'web' ? (
    //       <SubscriptionSwitchWeb
    //         sessionId={loadDetails?.sessionId}
    //         hasNextStop={loadDetails?.nextStop}
    //       />
    //     ) : (
    //       <AppSubscriptionSwitch hasNextStop={loadDetails?.nextStop} />
    //     )}
    //     {/* {Platform.OS === 'web' ? (
    //       <BottomDrawerWeb loadDetails={loadDetails} />
    //     ) : (
    //       <BottomDrawerLab loadDetails={loadDetails} />
    //     )} */}
    //   </View>
    // </ScrollView>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    paddingTop: 100,
  },
});

export default HomePage;
