import React from 'react';
import {
  TouchableOpacity,
  View,
  StyleSheet,
  Platform,
  Image,
  StatusBar,
} from 'react-native';
import WhiteLogo from '../assets/BBIWhiteLogo.png';
import { Ionicons } from '@expo/vector-icons';
import LoadingLine from './Helper/LoadingLine';

export default function Header({ onBackPress, onLogoPress, bg, isLoading }) {
  const STATUSBAR_HEIGHT = StatusBar.currentHeight;
  const styles = StyleSheet.create({
    container: {
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      width: '100%',
      paddingTop: Platform.OS === 'web' ? 10 : 0,
      paddingBottom: STATUSBAR_HEIGHT ? STATUSBAR_HEIGHT / 2.0 : 10,
      zIndex: '0',
      backgroundColor: bg,
    },
    logoImage: {
      height: 75,
      width: 75,
      alignSelf: 'center',
    },
    icon: {
      color: 'white',
      fontSize: 30,
      paddingLeft: 25,
    },
    headerSection: {
      flex: 1,
    },
  });

  return (
    <View style={styles.container}>
      <View style={styles.headerSection}>
        {typeof onBackPress === 'function' && (
          <TouchableOpacity onPress={onBackPress}>
            <Ionicons name="arrow-back" style={styles.icon} />
          </TouchableOpacity>
        )}
      </View>
      <View style={styles.headerSection}>
        {/* Always render Image inside TouchableOpacity */}
        <TouchableOpacity onPress={onLogoPress} disabled={!onLogoPress}>
          <Image source={WhiteLogo} style={styles.logoImage} />
        </TouchableOpacity>
      </View>
      <View style={styles.headerSection}></View>
      {(isLoading || false) && <LoadingLine />}
    </View>
  );
}
