import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
  useMemo,
} from 'react';
import {
  SafeAreaView,
  StatusBar,
  View,
  useWindowDimensions,
  Platform,
  StyleSheet,
} from 'react-native';
import GetGlobalStyles from './assets/GlobalStyles';
import GetLoad, { GetUrlGuid } from './auth/Load';
import FourOFourPage from './Pages/404Page';
import LoadingPage from './Pages/LoadingPage';
import HomePage from './Pages/HomePage';
import WelcomePage from './Pages/WelcomePage';
import SessionModal from './Components/SessionModal';
import Header from './Components/Header';
import BottomSheet, {
  BottomSheetScrollView,
  BottomSheetView,
} from '@gorhom/bottom-sheet';
import Details from './Components/Details';

export default function App() {
  const [loadDetails, setLoadDetails] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [sessionGuid, setSessionGuid] = useState(null);
  const { height, width } = useWindowDimensions();
  const [modalOpen, setModalOpen] = useState(false);
  const globalStyles = GetGlobalStyles(height, width);

  // DRAWER ///////////////////////////////////////////////////
  // drawer hooks
  const sheetRef = useRef(null);

  // drawer variables
  const snapPoints = useMemo(() => [100, '50%', '100%'], []);

  // drawer callbacks
  const handleSheetChange = useCallback(index => {
    // console.log('handleSheetChange', index);
  }, []);
  const handleSnapPress = useCallback(index => {
    sheetRef.current?.snapToIndex(index);
  }, []);
  const handleClosePress = useCallback(() => {
    sheetRef.current?.close();
  }, []);
  // DRAWER ///////////////////////////////////////////////////

  async function EnterSessionFromUrl() {
    const initUrl = await GetUrlGuid();
    initUrl && HandleSessionSelect(initUrl);
  }

  useEffect(() => {
    EnterSessionFromUrl();
  }, []);

  async function HandleRefreshSession() {
    if (sessionGuid) {
      setIsLoading(true);
      const loadDetails = await GetLoad(sessionGuid);
      setLoadDetails(loadDetails);
      setIsLoading(false);
    }
  }

  async function HandleSessionSelect(guid) {
    if (guid) {
      if (guid.length < 36) {
        alert('Enter at least 36 characters');
      } else {
        setIsLoading(true);
        setModalOpen(false);
        handleClosePress();

        // Create a promise that resolves after .5 seconds
        const delay = new Promise(resolve => setTimeout(resolve, 500));
        // This is purely for the cool loading animation lol...
        // Start both the delay and GetLoad concurrently
        const [_, loadDetails] = await Promise.all([delay, GetLoad(guid)]);

        // Continue with the rest of the function after both promises have resolved
        setIsLoading(false);
        setModalOpen(false);
        setLoadDetails(loadDetails);
        setSessionGuid(guid);
        if (Platform.OS === 'web') {
          window.history.replaceState(null, 'New Session', '/?id=' + guid);
        }
        loadDetails && handleSnapPress(0);
      }
    }
  }

  const STYLES = ['default', 'dark-content', 'light-content'];
  const TRANSITIONS = ['fade', 'slide', 'none'];

  const styles = StyleSheet.create({
    container: {
      flex: 1,
      justifyContent: 'center',
      backgroundColor: sessionGuid ? '#353536' : '#000',
      // backgroundColor: !sessionGuid ? '#000' : '#353536',
    },
  });

  function ReturnAndReset() {
    setSessionGuid(null);
    setLoadDetails(null);
    setIsLoading(false);
    setModalOpen(false);
    handleClosePress();
  }

  function OpenModal() {
    setModalOpen(true);
  }

  // Determine the function for the logo press based on the conditions
  const logoPressHandler = sessionGuid ? OpenModal : null;
  const backPressHandler = sessionGuid ? ReturnAndReset : null;

  useEffect(() => {
    console.log(loadDetails);
  }, [loadDetails]);

  return (
    <SafeAreaView style={styles.container}>
      <StatusBar
        animated={true}
        backgroundColor={sessionGuid ? '#353536' : '#353536'}
        barStyle={STYLES[2]}
        showHideTransition={TRANSITIONS[0]}
      />
      <View style={globalStyles.appContainer}>
        <Header
          onLogoPress={logoPressHandler}
          onBackPress={backPressHandler}
          bg={sessionGuid ? '#353536' : '#000'}
          isLoading={isLoading}
        />
        {!sessionGuid ? (
          <WelcomePage
            HandleSessionSelect={HandleSessionSelect}
            isLoading={isLoading}
          />
        ) : isLoading || loadDetails ? (
          <HomePage
            loadDetails={loadDetails}
            OpenModal={OpenModal}
            ResetGuid={ReturnAndReset}
            isLoading={isLoading}
            HandleRefreshSession={HandleRefreshSession}
          />
        ) : (
          <FourOFourPage />
        )}

        <SessionModal
          isOpen={modalOpen}
          CloseModal={() => setModalOpen(false)}
          HandleSessionSelect={HandleSessionSelect}
          isLoading={isLoading}
          currentGuid={sessionGuid}
        />
        <BottomSheet
          index={-1}
          ref={sheetRef}
          snapPoints={snapPoints}
          onChange={handleSheetChange}
        >
          {Platform.OS === 'web' ? (
            <BottomSheetView>
              <Details loadDetails={loadDetails} />
            </BottomSheetView>
          ) : (
            <BottomSheetScrollView>
              <Details loadDetails={loadDetails} />
            </BottomSheetScrollView>
          )}
        </BottomSheet>
      </View>
    </SafeAreaView>
  );
}
