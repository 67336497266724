import React, { useEffect, useRef } from 'react';
import { Animated, useWindowDimensions } from 'react-native';
import { LinearGradient } from 'expo-linear-gradient';

const LoadingLine = () => {
  const { width } = useWindowDimensions();
  const moveAnim = useRef(new Animated.Value(-width)).current; // Start off-screen

  useEffect(() => {
    Animated.loop(
      Animated.timing(moveAnim, {
        toValue: width, // Move to outside the right edge
        duration: 750,
        useNativeDriver: false,
      }),
    ).start();
  }, [moveAnim]);

  const lineStyle = {
    position: 'absolute',
    bottom: 0,
    height: 2,
    width: width,
    transform: [{ translateX: moveAnim }],
  };

  return (
    <Animated.View style={lineStyle}>
      <LinearGradient
        colors={['transparent', 'white', 'white', 'transparent']}
        start={{ x: 0, y: 0 }}
        end={{ x: 1, y: 0 }}
        style={{ width: '100%', height: '100%' }}
      />
    </Animated.View>
  );
};

export default LoadingLine;
