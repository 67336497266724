import { View, StyleSheet, useWindowDimensions, Switch } from 'react-native';
import { requestForegroundPermissionsAsync } from 'expo-location';
import PostPing from './Helper/PostPing';
import { useState, useEffect, useCallback, useRef } from 'react';

// Custom Hook to handle location logic
function useLocation(sessionId) {
  const getPosIntermittent = useRef(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isEnabled, setIsEnabled] = useState(false);
  const [subscription, setSubscription] = useState();
  const [failedToPost, setFailedToPost] = useState(false);
  const [succesfullyPosted, setSuccessfullyPosted] = useState(false);

  //gain permission to get location in the forground
  const getPermission = useCallback(async () => {
    let permission = await requestForegroundPermissionsAsync();
    return permission.granted;
  }, []);

  //gets position when called
  const getPosition = useCallback(() => {
    function success(position) {
      postPings(position);
    }
    function error(err) {
      console.error(`ERROR(${err.code}): ${err.message}`);
    }
    navigator.geolocation.getCurrentPosition(success, error, {
      enableHighAccuracy: true,
      maximumAge: 0,
    });
  }, [sessionId]);

  //getting the position every minute
  const getPositionIntermittently = useCallback(() => {
    const getLoco = setInterval(() => {
      if (getPosIntermittent.current) {
        getPosition();
      } else {
        clearInterval(getLoco);
      }
    }, 60000); //PROD: 60000 * 15: set for 15 minutes. Suggested from Wack
  }, [getPosition]);

  //calls PostPing to post the ping in the background
  const postPings = useCallback(
    async newLocation => {
      if (newLocation.coords) {
        //let UTCTimeStamp = new Date(newLocation.timestamp).toISOString();
        const { latitude, longitude } = newLocation.coords;
        await PostPing(latitude, longitude, sessionId)
          .then(() => {
            setSuccessfullyPosted(true);
            setIsLoading(false);
          })
          .catch(() => {
            setIsLoading(false);
            setFailedToPost(true);
          });
      } else {
        setIsLoading(false);
        setFailedToPost(true);
      }
    },
    [sessionId],
  );

  //starts a subscription of getting the position
  const startSubscription = useCallback(async () => {
    getPosIntermittent.current = true;
    setIsLoading(true);
    setFailedToPost(false);
    setSuccessfullyPosted(false);
    getPosition();
    getPositionIntermittently();
  }, [getPosition, getPositionIntermittently, postPings]);

  //ends the subscription
  const endSubscription = useCallback(() => {
    setIsEnabled(false);
    getPosIntermittent.current = false;
    if (subscription && subscription.remove) {
      subscription.remove();
    }
    setSubscription(null);
    setIsLoading(false);
  }, [subscription]);

  const handleSwitch = useCallback(() => {
    if (isEnabled === false) {
      startSubscription();
    } else {
      endSubscription();
    }
  }, [isEnabled, startSubscription, endSubscription]);

  useEffect(() => {
    if (failedToPost === true) {
      endSubscription();
      getPosIntermittent.current = false;
      setFailedToPost(false);
    }

    if (succesfullyPosted) {
      if (getPosIntermittent.current) {
        setIsEnabled(true);
      }
      setSuccessfullyPosted(false);
    }
  }, [failedToPost, subscription, succesfullyPosted, endSubscription]);

  return {
    getPermission,
    handleSwitch,
    isLoading,
    isEnabled,
  };
}

// Custom component for LocationSwitch
function LocationSwitch({
  isEnabled,
  isLoading,
  hasNextStop,
  handleSwitch,
  height,
  permissionAllowed,
}) {
  const styles = getStyles(height);
  return (
    <View style={styles.container}>
      <View
        style={[
          styles.switchContainer,
          // { backgroundColor: isEnabled ? '#0cb41c' : '#Bf0909' },
          {
            opacity: isLoading || !hasNextStop || !permissionAllowed ? 0.2 : 1,
          },
        ]}
      >
        <Switch
          trackColor={{ false: '#Bf0909', true: '#0cb41c' }}
          ios_backgroundColor={'#Bf0909'}
          thumbColor={'white'}
          activeThumbColor={'white'}
          onValueChange={handleSwitch}
          value={isEnabled}
          disabled={isLoading || !hasNextStop || !permissionAllowed}
          style={{
            ...styles.switch,
            transform: [{ scaleX: 1.5 }, { scaleY: 1.5 }],
            opacity: isLoading || !hasNextStop || !permissionAllowed ? 0.2 : 1,
          }}
        />
      </View>
    </View>
  );
}

function getStyles(height) {
  return StyleSheet.create({
    container: {
      alignSelf: 'center',
    },
  });
}

// Main component
export default function SubscriptionSwitchWeb({ sessionId, hasNextStop }) {
  const { height } = useWindowDimensions();
  const { getPermission, handleSwitch, isLoading, isEnabled } =
    useLocation(sessionId);
  const [permissionAllowed, setPermissionAllowed] = useState(null);

  useEffect(() => {
    async function checkPermission() {
      const isAllowed = await getPermission();
      if (isAllowed === false) {
        alert(
          'Please Allow Access to Location In Your Settings, Then Reload the Page',
        );
      }
      setPermissionAllowed(isAllowed);
    }
    checkPermission();
  }, [getPermission]);

  return (
    <LocationSwitch
      isEnabled={isEnabled}
      isLoading={isLoading}
      hasNextStop={hasNextStop}
      handleSwitch={handleSwitch}
      height={height}
      permissionAllowed={permissionAllowed}
    />
  );
}
