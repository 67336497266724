import React from 'react';
import {
  View,
  StyleSheet,
  KeyboardAvoidingView,
  Platform,
  ScrollView,
  useWindowDimensions,
  Pressable,
} from 'react-native';
import Modal from 'react-native-modal';
import SessionSelector from './SessionSelector';

export default function SessionModal({
  isOpen,
  CloseModal,
  HandleSessionSelect,
  isLoading,
  currentGuid,
}) {
  const { height, width } = useWindowDimensions();

  const styles = StyleSheet.create({
    modal: {
      display: 'flex',
      alignItems: 'center',
    },
    modalView: {
      flexGrow: 1,
      alignItems: 'center',
      justifyContent: 'center',
      width: '100%',
    },
    keyboardAvoidingContainer: {
      flex: 1,
      justifyContent: 'center',
      width: '100%',
      maxWidth: 500,
    },
    test: {
      flex: 1,
      width: '100%',
      cursor: 'default',
    },
  });

  return (
    <Modal
      isVisible={isOpen}
      hasBackdrop={true}
      onBackdropPress={CloseModal}
      backdropColor="black"
      backdropOpacity={0.9}
      deviceWidth={width}
      deviceHeight={height}
      animationType="fade"
      animationInTiming={150}
      animationOutTiming={150}
      animationIn="slideInUp"
      animationOut="slideOutDown"
      style={styles.modal}
    >
      <KeyboardAvoidingView
        behavior={Platform.OS === 'ios' ? 'padding' : 'height'}
        style={styles.keyboardAvoidingContainer}
      >
        <ScrollView
          contentContainerStyle={styles.modalView}
          keyboardShouldPersistTaps="handled"
        >
          <Pressable onPress={CloseModal} style={styles.test} />
          <SessionSelector
            HandleSessionSelect={HandleSessionSelect}
            isLoading={isLoading}
            isLight={true}
            currentGuid={currentGuid}
          />
          <Pressable onPress={CloseModal} style={styles.test} />
        </ScrollView>
      </KeyboardAvoidingView>
    </Modal>
  );
}
