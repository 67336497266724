import axios from 'axios';

const devUrl =
  'https://bbiknowwhereyouarebackend20230628142933.azurewebsites.net/api/';
const localUrl = 'https://localhost:44379/api/';

//SWITCH DEPENDING ON ENVIRONMENT
const BBIKnowCaller = axios.create({
  baseURL: devUrl,
});

export default BBIKnowCaller;
