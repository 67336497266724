import AsyncStorage from '@react-native-async-storage/async-storage';

export async function StoreData(key, value) {
  try {
    await AsyncStorage.setItem(key, value);
    return { status: 'OK' };
  } catch (e) {
    return { status: 'error', error: e };
  }
}

export async function GetData(key) {
  try {
    const value = await AsyncStorage.getItem(key);
    return { status: 'OK', value: value };
  } catch (e) {
    return { status: 'error', error: e };
  }
}

export async function ClearData() {
  try {
    await AsyncStorage.clear();
    return { status: 'OK' };
  } catch (e) {
    return { status: 'error', error: e };
  }
}
