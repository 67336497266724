import {
  View,
  StyleSheet,
  KeyboardAvoidingView,
  Platform,
  ScrollView,
} from 'react-native';
import GetGlobalStyles from '../assets/GlobalStyles';
import Header from '../Components/Header';
import WelcomeSign from '../Components/Helper/WelcomeSign';
import SessionSelector from '../Components/SessionSelector';
import { useState } from 'react';

//The welcome page is pretty simple. It has the header Logo, a welcome sign, and the button to open the modal will also show due to it being on every page

export default function WelcomePage({ HandleSessionSelect, isLoading }) {
  const styles = StyleSheet.create({
    welcomePage: {
      flex: 1,
      backgroundColor: '#000',
      width: '100%',
    },
    keyboardAvoidingContainer: {
      flex: 1,
      width: '100%',
      justifyContent: 'center',
      alignItems: 'center',
    },
  });

  return (
    <View style={styles.welcomePage}>
      <KeyboardAvoidingView
        behavior={Platform.OS === 'ios' ? 'padding' : 'height'}
        style={{ flex: 1 }}
      >
        <ScrollView
          bounces={false}
          contentContainerStyle={{
            flexGrow: 1,
            alignItems: 'center',
            justifyContent: 'center',
            paddingBottom: '0%',
          }}
          keyboardShouldPersistTaps="handled"
        >
          <SessionSelector
            HandleSessionSelect={HandleSessionSelect}
            isLoading={isLoading}
          />
        </ScrollView>
      </KeyboardAvoidingView>
      <WelcomeSign />
    </View>
  );
}
