import React, { useEffect } from 'react';
import { View, Switch, StyleSheet, useWindowDimensions } from 'react-native';
import * as Location from 'expo-location';
import * as TaskManager from 'expo-task-manager';
import PostPing from './Helper/PostPing';
import { GetData } from '../auth/AsyncStorage';

export default function SubscriptionSwitch(hasNextStop) {
  const [locationStarted, setLocationStarted] = React.useState(false);
  const { height } = useWindowDimensions();
  const switchDisabled = hasNextStop === null;

  const startLocationTracking = async () => {
    // accuracy (optional)
    // distanceInterval (optional): Receive updates only when the location has changed by at least this distance in meters.
    // timeInterval (optional) (android only): Minimum time to wait between each update in milliseconds.
    await Location.startLocationUpdatesAsync('location-tracking', {
      accuracy: Location.Accuracy.Highest,
      timeInterval: 600000,
      // distanceInterval: 10000,
      distanceInterval: 10,
    });
    const hasStarted = await Location.hasStartedLocationUpdatesAsync(
      'location-tracking',
    );
    setLocationStarted(hasStarted);
  };

  useEffect(() => {
    const config = async () => {
      let resf = await Location.requestForegroundPermissionsAsync().catch(
        e => {},
      );
      let resb = await Location.requestBackgroundPermissionsAsync().catch(
        e => {},
      );
      if (resf.status !== 'granted' || resb.status !== 'granted') {
        //console.log('Permission to access location was denied');
      } else {
        //console.log('Permission to access location granted');
      }
    };
    config();
  }, []);

  const startLocation = () => {
    startLocationTracking();
  };

  const stopLocation = () => {
    setLocationStarted(false);
    TaskManager.isTaskRegisteredAsync('location-tracking').then(tracking => {
      if (tracking) {
        Location.stopLocationUpdatesAsync('location-tracking');
      }
    });
  };

  const toggleLocationTracking = value => {
    value ? startLocation() : stopLocation();
  };

  const styles = StyleSheet.create({
    container: {
      alignSelf: 'center',
    },
    switch: {
      transform: [{ scaleX: 1.5 }, { scaleY: 1.5 }],
      shadowOffset: { width: 0, height: 0 }, // Shadow offset for IOS
      shadowColor: '#fff',
      shadowRadius: 5, // Shadow radius for IOS
      elevation: 5, // Elevation for Android
    },
  });

  return (
    <View style={styles.container}>
      <Switch
        disabled={switchDisabled}
        trackColor={{ false: '#3c3c3c', true: '#0cb41c' }}
        ios_backgroundColor={'#3c3c3c'}
        thumbColor={'white'}
        activeThumbColor={'white'}
        onValueChange={toggleLocationTracking}
        value={locationStarted}
        style={[styles.switch, { shadowOpacity: locationStarted ? 0.5 : 0 }]}
      />
    </View>
  );
}

TaskManager.defineTask('location-tracking', async ({ data, error }) => {
  if (error) {
    return;
  }
  if (data) {
    const { locations } = data;
    let lat = locations?.[0]?.coords?.latitude ?? 0;
    let long = locations?.[0]?.coords?.longitude ?? 0;

    if (lat !== 0 && long !== 0) {
      const sessionId = ParseInt(GetData('bbidrive_sessionID'));
      PostPing(lat, long, sessionId);
    }
  }
});
