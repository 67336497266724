import { StyleSheet } from 'react-native';

export default function GetGlobalStyles(height, width) {
  return StyleSheet.create({
    appContainer: {
      position: 'relative',
      backgroundColor: '#353536',
      height: '100%',
      width: '100%',
      overflow: 'hidden',
    },
    app: {
      position: 'relative',
      backgroundColor: '#353536',
      height: '100%',
      width: '100%',
      overflow: 'hidden',
      alignItems: 'center',
    },
    smallSeshButton: {
      position: 'absolute',
      right: 20,
      top: 25,
      zIndex: 1,
      borderRadius: 8,
      padding: 5,
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: '#F7F7F7',
    },
    bigSeshButton: {
      backgroundColor: 'white',
      position: 'absolute',
      top: 0.5 * height - 0.15 * width,
      right: 0.5 * width - 0.15 * width,
      width: 0.3 * width,
      height: 0.3 * width,
      zIndex: 1,
      borderRadius: 8,
      padding: 0,
      justifyContent: 'center',
      alignItems: 'center',
    },
    OpenInAppButton: {
      backgroundColor: 'white',
      position: 'absolute',
      left: 0.5 * width,
      top: 0.34 * height,
      width: 0.3 * width,
      height: 75,
      justifyContent: 'center',
      borderRadius: 25,
      paddingRight: 7,
      paddingLeft: 7,
    },
    OpenInAppText: {
      fontWeight: 'bold',
      fontSize: 25,
    },
  });
}
