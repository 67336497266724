import React from 'react';
import { View, Text, StyleSheet } from 'react-native';

const Four0FourSign = () => {
  return (
    <View style={styles.signWrapper}>
      <View style={styles.outerExitSignContainer}>
        <View style={styles.innerExitSignContainer}>
          <Text style={styles.exitText}>EXIT</Text>
        </View>
      </View>
      <View style={styles.outerSignContainer}>
        <View style={styles.innerSignContainer}>
          <Text style={styles.signTextBold}>404</Text>
          <Text style={styles.signText}>PAGE NOT FOUND</Text>
        </View>
      </View>
      <View style={styles.stakes}>
        <View style={styles.stake}></View>
        <View style={styles.stake}></View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  signWrapper: {
    maxWidth: 250,
    width: '100%',
    alignSelf: 'center',
    alignItems: 'center',
  },
  outerExitSignContainer: {
    width: '33%',
    alignSelf: 'end',
    backgroundColor: '#000', //Green Sign: #4F772D
    padding: 2, // This will be the border width
    borderRadius: 8,
    alignItems: 'center',
    justifyContent: 'center',
  },
  innerExitSignContainer: {
    paddingVertical: 8, // Adjust padding to be slightly less than the outer container to create the border effect
    borderRadius: 6, // Adjust to be slightly less than the outer container's border radius
    borderWidth: 3,
    borderColor: '#fff',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
  },
  exitText: {
    color: '#fff',
    fontSize: 20,
    fontWeight: 'normal',
  },
  outerSignContainer: {
    width: '100%',
    backgroundColor: '#000', //Green Sign: #4F772D
    padding: 2, // This will be the border width
    borderRadius: 8,
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: 50,
  },
  innerSignContainer: {
    paddingVertical: 15, // Adjust padding to be slightly less than the outer container to create the border effect
    borderRadius: 6, // Adjust to be slightly less than the outer container's border radius
    borderWidth: 3,
    borderColor: '#fff',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
  },
  signText: {
    color: '#fff',
    fontSize: 25,
    fontWeight: 'normal',
  },
  signTextBold: {
    color: '#fff',
    fontSize: 32,
    fontWeight: 'bold',
  },
  stakes: {
    paddingHorizontal: 0,
    width: '75%',
    position: 'absolute',
    bottom: 0,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  stake: {
    backgroundColor: '#FFF',
    height: 50,
    width: 3,
    borderRadius: 0,
  },
});

export default Four0FourSign;
