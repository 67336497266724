import BBIKnowCaller from '../../api/BBIKnowCaller';

export default async function PostPing(latitude, longitude, sessionId) {
  return new Promise((resolve, reject) => {
    //39.9814261, -83.0218396, '2023-07-24T13:06:45.641Z', 282
    // const postPingParams = {
    //   //TimeStamp: new Date(Date.now()).toISOString(),
    //   Lat: '39.9814261',
    //   Long: '-83.0218396',
    //   SessionId: '282',
    // };

    // const formData = new URLSearchParams(postPingParams);
    // const options = {
    //   headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    // };
    const jsonFormat = {
      Lat: latitude,
      Long: longitude,
      SessionId: sessionId,
    };
    BBIKnowCaller.post(`Pings`, jsonFormat)
      .then(response => {
        //console.log('response', response);
        resolve('');
      })
      .catch(error => {
        //console.log('error', JSON.stringify(error, null, 4));
        reject('');
      });
  });
}
