import React, { useEffect, useState } from 'react';
import { GetCookieGuid, GetUrlGuid } from '../auth/Load';
import { Entypo } from '@expo/vector-icons';
import {
  View,
  Text,
  TextInput,
  TouchableOpacity,
  StyleSheet,
  ScrollView,
  ActivityIndicator,
  Keyboard,
} from 'react-native';

const SessionSelector = ({
  HandleSessionSelect,
  isLoading,
  isLight,
  currentGuid,
}) => {
  const [sessionInput, setSessionInput] = useState('');
  const [prevSession, setPrevSession] = useState();
  const [url, setUrl] = useState();

  const styles = GetStyles(isLight, isLoading);

  async function GetGuis() {
    const [clientGuid, urlGuid] = await Promise.all([
      GetCookieGuid(),
      GetUrlGuid(),
    ]);
    setPrevSession(clientGuid);
    setUrl(urlGuid);
  }

  useEffect(() => {
    GetGuis();
  }, [isLoading]);

  const onSelectSession = session => {
    setSessionInput(session);
  };

  const onEnterSession = () => {
    if (sessionInput) {
      HandleSessionSelect(sessionInput);
    } else {
      alert('Please enter a valid session ID');
    }
  };

  return (
    <View style={styles.container}>
      <Text style={styles.subtitle}>Select or Enter Your Session ID</Text>
      {/* Custom session input with integrated button */}
      <View style={styles.inputContainer}>
        <TextInput
          style={styles.input}
          placeholder="Enter your Session ID"
          placeholderTextColor="#AAA"
          value={sessionInput}
          onChangeText={setSessionInput}
          keyboardType="default"
          onBlur={() => Keyboard.dismiss}
          editable={!isLoading}
        />
        <TouchableOpacity
          style={styles.enterButton}
          onPress={onEnterSession}
          disabled={isLoading}
        >
          {/* <Text style={styles.enterButtonText}>Go</Text> */}
          {isLoading ? (
            <ActivityIndicator size="small" color="#ffffff" />
          ) : (
            <Text style={styles.enterButtonText}>
              <Entypo name="arrow-right" size={24} color="black" />
            </Text>
          )}
        </TouchableOpacity>
      </View>

      {url && url != currentGuid && (
        <TouchableOpacity
          style={styles.sessionButton}
          onPress={() => onSelectSession(url)}
          disabled={isLoading}
        >
          <Text style={styles.sessionText}>Join Session From Link</Text>
          <Text style={styles.sessionSubText}>{url}</Text>
        </TouchableOpacity>
      )}
      {prevSession && prevSession != currentGuid && (
        <TouchableOpacity
          style={styles.sessionButton}
          onPress={() => onSelectSession(prevSession)}
          disabled={isLoading}
        >
          <Text style={styles.sessionText}>Recent Session</Text>
          <Text style={styles.sessionSubText}>{prevSession}</Text>
        </TouchableOpacity>
      )}
      {currentGuid && (
        <TouchableOpacity
          style={styles.sessionButton}
          onPress={() => onSelectSession(prevSession)}
          disabled={isLoading}
        >
          <Text style={styles.sessionText}>Current Session</Text>
          <Text style={styles.sessionSubText}>{prevSession}</Text>
        </TouchableOpacity>
      )}
    </View>
  );
};

export default SessionSelector;

function GetStyles(isLight, isLoading) {
  return StyleSheet.create({
    container: {
      paddingVertical: 20,
      paddingHorizontal: 20,
      alignItems: 'center',
      backgroundColor: isLight ? '#353536' : '#000',
      maxWidth: 500,
      width: '100%',
      borderRadius: 8,
    },
    subtitle: {
      fontSize: 20,
      color: '#CCC',
    },
    sessionButton: {
      backgroundColor: !true ? '#314E7C' : '#fff',
      borderRadius: 8,
      marginTop: 8,
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%',
      height: 50,
    },
    sessionText: {
      color: 'black',
      textAlign: 'center',
      fontSize: 18,
      opacity: isLoading ? 0.5 : 1,
    },
    sessionSubText: {
      color: 'black',
      textAlign: 'center',
      fontSize: 12,
      opacity: isLoading ? 0.5 : 1,
    },
    inputContainer: {
      flexDirection: 'row',
      // borderWidth: 2,
      // borderColor: '#353536',
      backgroundColor: '#353536',
      borderRadius: 8,
      marginTop: 8,
      width: '100%',
      height: 50,
      overflow: 'hidden',
    },
    input: {
      flex: 1,
      justifyContent: 'center',
      alignItems: 'center',
      color: '#FFF',
      fontSize: 14,
      paddingLeft: 8,
      opacity: isLoading ? 0.5 : 1,
    },
    enterButton: {
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: '#fff',
      // backgroundColor: '#4F772D',
      padding: 0,
      width: 75, // Set a fixed width
      height: '100%', // Set a fixed height
      opacity: isLoading ? 0.5 : 1,
    },
    enterButtonText: {
      color: 'white',
      fontSize: 18,
    },
  });
}
